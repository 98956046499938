//Constants
export const STATE_PLAYING = "PLAYING";
export const STATE_FINISH = "FINISHED";
export const States = [STATE_PLAYING, STATE_FINISH];

export const AllColors = [
    "#3357FF", // Blue
    "#FF33A1", // Pink
    "#FF5733", // Red Orange
    "#33FF57", // Lime Green
    "#FF8333", // Orange
    "#33FFF7", // Aqua
    "#8D33FF", // Purple
    "#FF3333", // Red
    "#33FF83", // Mint Green
    "#5733FF", // Indigo
    "#FF338D", // Hot Pink
    "#FF5733", // Coral
    "#33A1FF", // Sky Blue
    "#57FF33", // Bright Green
    "#FF33F7", // Magenta
    "#FF5733", // Peach
    "#338DFF", // Light Blue
    "#A1FF33", // Yellow Green
    "#FF33D1", // Deep Pink
    "#5733FF", // Violet
];

export const IconColors = [
    "#3357FF", // Blue
    "#FF5733", // Peach
    "#FF8333", // Orange
    "#FF338D", // Hot Pink
    "#8D33FF", // Purple
    "#FF3333", // Red
    "#FF5733", // Red Orange
    "#A1FF33", // Yellow Green
];

//Vars
// export let ColorsCount = 2;
export let GameState = STATE_PLAYING;
export let Configs = sessionStorage.getItem("settings")
    ? JSON.parse(sessionStorage.getItem("settings"))
    : {
          rainbow: true,
          colorCount: 4,
      };
export let BoxNumberRange = [3, 4, 5, 6, 7, 8];
//Functions
export const GetConfigValue = (name) => {
    return Configs[name];
};

export const SetConfigValue = (name, value) => {
    Configs[name] = value;
    sessionStorage.setItem("settings", JSON.stringify(Configs));
    notifySubscribers(name);
};

export const FinishGame = () => {
    GameState = STATE_FINISH;
};

export const StateIsPlaying = () => {
    return GameState === STATE_PLAYING;
};

export let boxWidth;

export const BoxWidth = () => {
    const isNarrowScreen = window.screen.width < 950;
    let toReturn;
    if (!isNarrowScreen) {
        boxWidth = "none";
    }

    if (boxWidth) {
        toReturn = boxWidth;
    } else {
        const rowWidth = 80 / (GetConfigValue("colorCount") + 2) + "vw";
        boxWidth = rowWidth;
        toReturn = boxWidth;
    }
    return toReturn;
};

// Subscripción
let subscribers = {};

export const subscribe = (name, callback) => {
    if (!subscribers[name]) {
        subscribers[name] = [];
    }
    subscribers[name].push(callback);
};

export const notifySubscribers = (name) => {
    if (subscribers[name]) {
        subscribers[name].forEach((callback) => callback(GetConfigValue(name)));
    }
};
