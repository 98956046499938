// FireworksComponent.js
import React, { useRef, useEffect } from "react";
import { Fireworks } from "fireworks-js";

const FireworksComponent = ({ trigger, rocketsCount }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (trigger) {
            const container = containerRef.current;

            const fireworks = new Fireworks(container, {
                particles: 150,
                explosionMinHeight: 0.2,
                explosionMaxHeight: 0.9,
                explosionChance: 0.4,
                delay: {
                    min: 80,
                    max: 100,
                },
                acceleration: 1,
                intensity: 50,
                gravity: 0,
                decay: {
                    min: 0.005,
                    max: 0.02,
                },
                lineWidth: {
                    explosion: {
                        min: 4,
                        max: 6,
                    },
                    trace: {
                        min: 4,
                        max: 6,
                    },
                },
                flickering: 90,
                traceLength: 3,
                rocketsPoint: {
                    min: 20,
                    max: 80,
                },
            });

            fireworks.launch(rocketsCount);
        }
    }, [trigger]);

    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: 0,
                pointerEvents: "none",
            }}
        />
    );
};

export default FireworksComponent;
