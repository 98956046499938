import React from "react";
import Row from "../Row/Row";
import CurrentRow from "../CurrentRow/CurrentRow";
import "./GameBoard.scss";
import "../../Utilities/index.scss";
import { StateIsPlaying } from "../../Utilities/Vars";

const GameBoard = ({ guesses, currentGuess, updateGuess }) => {
    return (
        <>
            {StateIsPlaying() ? (
                <CurrentRow guess={currentGuess} updateGuess={updateGuess} />
            ) : (
                ""
            )}
            <hr />
            {[...guesses].map((item, index) => (
                <tr>
                    <Row
                        key={index}
                        guess={item.guess}
                        feedback={item.feedback}
                    />
                </tr>
            ))}
        </>
    );
};

export default GameBoard;
