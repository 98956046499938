import React, { useState } from "react";
import "./Settings.scss";
import Switch from "@mui/material/Switch";

import { ReactSVG } from "react-svg";
import IncorrectIcon from "../../Assets/Icons/IncorrectIcon.svg";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
    AllColors,
    GetConfigValue,
    SetConfigValue,
    Configs,
    BoxNumberRange,
} from "../../Utilities/Vars";

const Settings = ({ show, onClose }) => {
    const [settings, setSettings] = useState(Configs);
    const [key, setKey] = useState(0);

    if (!show) return null;

    const changeConfigValue = (settingName, value) => {
        const newSettings = { ...settings };
        newSettings[settingName] = value;
        setSettings(newSettings);
        Object.keys(settings).forEach((settingKey) => {
            if (
                GetConfigValue(settingKey) !== undefined &&
                GetConfigValue(settingKey) !== newSettings[settingKey]
            ) {
                SetConfigValue(settingKey, newSettings[settingKey]);
            }
        });
    };

    return (
        <div className="overlay">
            <div className="container">
                <button className="close-button" onClick={onClose}>
                    <ReactSVG src={IncorrectIcon} />
                </button>
                <h2
                    className="title no-select"
                    onClick={() => setKey((currentKey) => currentKey + 1)}
                >
                    {"SETTINGS".split("").map((char, index) => (
                        <span
                            key={index}
                            style={{
                                color: AllColors[
                                    Math.floor(Math.random() * AllColors.length)
                                ],
                            }}
                        >
                            {char}
                        </span>
                    ))}
                </h2>
                <div className="option">
                    <label className="label">Title Animation</label>
                    <Switch
                        checked={settings["rainbow"]}
                        onChange={(e) =>
                            changeConfigValue("rainbow", !settings["rainbow"])
                        }
                    />
                </div>
                <div className="option">
                    <label className="label">Boxes</label>
                    <Select
                        value={settings["colorCount"]}
                        onChange={(e) =>
                            changeConfigValue("colorCount", e.target.value)
                        }
                    >
                        {BoxNumberRange.map((number) => (
                            <MenuItem key={number} value={number}>
                                {number}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    );
};

export default Settings;
