import React from "react";
import "./Row.scss";
import "../../Utilities/index.scss";

import { BoxWidth } from "../../Utilities/Vars";

const Row = ({ guess, feedback }) => {
    return (
        <div className="row">
            <div className="cells-wrapper">
                {guess.map((color, index) => (
                    <td
                        key={index}
                        className="cell"
                        style={{
                            backgroundColor: color,
                            width: BoxWidth(),
                        }}
                    ></td>
                ))}
            </div>
            {feedback && (
                <div className="feedback-wrapper">
                    <td>
                        <div className="feedback-item no-select correct">
                            {feedback.correct}
                        </div>
                    </td>
                    <td>
                        <div className="feedback-item no-select incorrect">
                            {feedback.incorrect}
                        </div>
                    </td>
                </div>
            )}
        </div>
    );
};

export default Row;
