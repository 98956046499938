import React, { useState, useEffect } from "react";
import GameBoard from "../GameBoard/GameBoard";
import "./App.scss";
import "../../Utilities/index.scss";
import Table from "react-bootstrap/Table";
import FireworksComponent from "../Fireworks/FireworksComponent";
import Settings from "../Settings/Settings";
import EndScreen from "../EndScreen/EndScreen";
import IconChanger from "../IconChanger/IconChanger";
import SettingsIcon from "../../Assets/Icons/SettingsIcon.svg";
import CoffeeIcon from "../../Assets/Icons/CoffeeIcon.svg";
import { ReactSVG } from "react-svg";

import {
    AllColors,
    IconColors,
    StateIsPlaying,
    FinishGame,
    GetConfigValue,
    subscribe,
    BoxWidth,
} from "../../Utilities/Vars";

const App = () => {
    const [colorsCount, setColorsCount] = useState(
        parseInt(GetConfigValue("colorCount"))
    );
    const [solution, setSolution] = useState([]);
    const [guesses, setGuesses] = useState([]);
    const [currentGuess, setCurrentGuess] = useState([]);
    const [title, setTitle] = useState("Colorle");
    const [useRainbow, setUseRainbow] = useState(GetConfigValue("rainbow"));
    const [showFireworks, setShowFireworks] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showEndScreen, setShowEndScreen] = useState(false);
    const [currentTitleColor, setCurrentTitleColor] = useState(
        AllColors[Math.floor(Math.random() * AllColors.length)]
    );

    useEffect(() => {
        const handleRainbowConfigChange = (newVal) => {
            setUseRainbow(newVal);
            if (!newVal) {
                setCurrentTitleColor(() => {
                    const filteredColors = [...AllColors].filter(
                        (color) => color !== currentTitleColor
                    );
                    return filteredColors[
                        Math.floor(Math.random() * filteredColors.length)
                    ];
                });
            }
        };
        const handleColorCountConfigChange = (newVal) => {
            setSolution(generateSolution());
            setCurrentGuess(constructGuess());
            setGuesses([]);
            parseInt(setColorsCount(newVal));
        };

        subscribe("rainbow", handleRainbowConfigChange);
        subscribe("colorCount", handleColorCountConfigChange);

        // Cleanup subscription on component unmount
        return () => {
            // Aquí iría la lógica para limpiar la suscripción
        };
    }, []);

    useEffect(() => {
        setSolution(generateSolution());
        setCurrentGuess(constructGuess());
    }, [colorsCount]);

    const redirectToIMAC = () => {
        window.open("https://buymeacoffee.com/flaggler", "_blank");
    };

    const toggleShowSettings = () => {
        setShowSettings(!showSettings);
    };

    const toggleShowEndScreen = () => {
        setShowEndScreen(!showEndScreen);
    };

    function constructGuess() {
        const out = [];
        for (let i = 0; i < colorsCount; i++) {
            out.push("white");
        }
        return out;
    }

    function generateSolution() {
        let solution = [];
        for (let i = 0; i < colorsCount; i++) {
            let randomColor =
                AllColors[Math.floor(Math.random() * colorsCount)];
            while (solution.includes(randomColor)) {
                randomColor =
                    AllColors[Math.floor(Math.random() * colorsCount)];
            }
            solution.push(randomColor);
        }
        return solution;
    }

    const checkGuess = (guess) => {
        let feedback = { correct: 0, incorrect: 0 };
        guess.forEach((color, index) => {
            if (color === solution[index]) {
                feedback.correct++;
            } else {
                feedback.incorrect++;
            }
        });
        if (feedback.incorrect === 0) {
            //WON
            FinishGame();
            setShowFireworks(true);
            // setTimeout(() => setShowFireworks(false), 10000); // Reset after fireworks animation
            toggleShowEndScreen();
        }
        return feedback;
    };

    const guessIsRepeated = (guess) => {
        if (guesses.length === 0) return false;
        const lastGuess = guesses[guesses.length - 1].guess;
        const lastGuessHash = lastGuess.join("");
        const guessHash = guess.join("");
        return guessHash === lastGuessHash;
    };

    const handleGuess = () => {
        if (guessIsRepeated(currentGuess)) {
            window.alert("You just tried that!");
            return;
        }
        if (
            currentGuess.filter((color) => color !== "white").length ===
            colorsCount
        ) {
            const feedback = checkGuess(currentGuess);
            setGuesses([...guesses, { guess: currentGuess, feedback }]);
            setCurrentGuess(constructGuess());
        }
    };

    const handleColorClick = (color) => {
        if (
            currentGuess.filter((color) => color !== "white").length <
                colorsCount &&
            !currentGuess.includes(color)
        ) {
            const currentGuessBackup = [...currentGuess];
            let index = currentGuessBackup.findIndex((x) => x === "white");
            currentGuessBackup[index] = color;
            setCurrentGuess([...currentGuessBackup]);
        }
    };

    const updateGuess = (newGuess) => {
        setCurrentGuess(newGuess);
    };

    const RainbowTitle = () => {
        let toReturn = (
            <h1 className="title no-select">
                {title.split("").map((letter, index) => (
                    <span
                        key={index}
                        className="rainbow-letter"
                        style={{ animationDelay: 0 }}
                    >
                        {letter}
                    </span>
                ))}
            </h1>
        );

        setTimeout(updateRainbowColors, 10);
        return toReturn;
    };

    const updateRainbowColors = () => {
        const letters = document.querySelectorAll(".rainbow-letter");

        letters?.forEach((letter, index) => {
            setTimeout(() => {
                letter.style.animationDelay = `${index * 1}s`;
            }, index * 1000);
        });
    };

    return (
        <div className={"App " + (useRainbow ? "background-animation" : "")}>
            {showFireworks && (
                <FireworksComponent
                    trigger={showFireworks}
                    rocketsCount={10 * colorsCount}
                />
            )}
            <Settings
                show={showSettings}
                onClose={toggleShowSettings}
                className="settings-button"
            />
            <EndScreen
                show={showEndScreen}
                onClose={toggleShowEndScreen}
                guesses={guesses}
                solution={solution}
                // className="settings-button"
            />
            <IconChanger
                characters={(title.toUpperCase() + " ").split("")}
                font="Danfo"
                size={100}
                colors={IconColors}
                interval={useRainbow ? 1200 : 9999999999} // Cambia el carácter cada 2 segundos
            />
            <div className="app-header">
                <div className="icon-setting cursor-pointer">
                    <ReactSVG
                        src={CoffeeIcon}
                        onClick={redirectToIMAC}
                        className="icon-size-coffee hover-effect-button"
                    />
                </div>
                {useRainbow ? (
                    RainbowTitle()
                ) : (
                    <h1
                        className="title no-select"
                        onClick={() =>
                            setCurrentTitleColor(() => {
                                const filteredColors = [...AllColors].filter(
                                    (color) => color !== currentTitleColor
                                );
                                return filteredColors[
                                    Math.floor(
                                        Math.random() * filteredColors.length
                                    )
                                ];
                            })
                        }
                        style={{
                            color: `${currentTitleColor}`,
                        }}
                    >
                        {title}
                    </h1>
                )}
                <div className="icon-setting cursor-pointer">
                    <ReactSVG
                        src={SettingsIcon}
                        onClick={toggleShowSettings}
                        className="icon-size-setting hover-effect-button"
                    />
                </div>
            </div>
            <Table className="game-table">
                <thead className="row">
                    <div className="cells-wrapper">
                        {AllColors.slice(0, colorsCount).map((color) => (
                            <th
                                key={color}
                                style={{
                                    backgroundColor: color,
                                    width: BoxWidth(),
                                }}
                                onClick={() => handleColorClick(color)}
                                className={
                                    "cell hover-effect-button " +
                                    (currentGuess.includes(color)
                                        ? "button-disabled"
                                        : "")
                                }
                                disabled={
                                    currentGuess.includes(color) ||
                                    !StateIsPlaying()
                                }
                            ></th>
                        ))}
                    </div>
                    <div
                        className={
                            "feedback-wrapper " +
                            (currentGuess.filter((color) => color !== "white")
                                .length < colorsCount
                                ? "disabled"
                                : "")
                        }
                    >
                        <button
                            className="button-submit"
                            onClick={handleGuess}
                            disabled={
                                currentGuess.filter(
                                    (color) => color !== "white"
                                ).length < colorsCount
                            }
                        >
                            SUBMIT
                        </button>
                    </div>
                </thead>
                <tbody>
                    <GameBoard
                        guesses={[...guesses].reverse()}
                        currentGuess={currentGuess}
                        updateGuess={updateGuess}
                    />
                </tbody>
            </Table>
        </div>
    );
};

export default App;
