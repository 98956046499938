import React, { useEffect, useState } from "react";

const DynamicIcon = ({ character, font, size, color }) => {
    useEffect(() => {
        const canvas = document.createElement("canvas");
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext("2d");

        ctx.fillStyle = color;
        ctx.font = `${size - 10}px ${font}`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(character, size / 2, size / 2);

        const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = canvas.toDataURL("image/png");
        document.getElementsByTagName("head")[0].appendChild(link);
    }, [character, font, size, color]);

    return null; // Este componente no renderiza nada
};

export default DynamicIcon;
