import React, { useState } from "react";
import "./EndScreen.scss";

import { ReactSVG } from "react-svg";
// import IncorrectIcon from "../../Assets/Icons/IncorrectIcon.svg";
import CoffeeIcon from "../../Assets/Icons/CoffeeIcon.svg";

import { AllColors } from "../../Utilities/Vars";

const EndScreen = (props) => {
    const [key, setKey] = useState(0);

    const redirectToIMAC = () => {
        window.open("https://buymeacoffee.com/flaggler", "_blank");
    };

    const watchAddAnReload = () => {
        //Watch add
        window.location.reload(false);
    };

    if (!props.show) return null;
    return (
        <div className="end-screen-overlay">
            <div className="end-screen-container">
                <h2
                    className="end-screen-title no-select"
                    onClick={() => setKey((currentKey) => currentKey + 1)}
                >
                    {"Well done!".split("").map((char, index) => (
                        <span
                            key={index}
                            style={{
                                color: AllColors[
                                    Math.floor(Math.random() * AllColors.length)
                                ],
                            }}
                        >
                            {char}
                        </span>
                    ))}
                </h2>
                <div className="history-container">
                    {[...props.guesses]
                        ?.reverse()
                        .map((guessObj, guessIndex) => (
                            <div
                                key={guessObj.guess.join("-") + guessIndex}
                                className="history-container-guess"
                            >
                                {guessObj.guess.map((color, index) => (
                                    <span
                                        key={`guess-sol-${color}-${index}`}
                                        className={`history-container-guess-color ${
                                            props.solution[index] === color
                                                ? "correct-record"
                                                : "incorrect-record"
                                        }`}
                                        style={{
                                            backgroundColor: color,
                                        }}
                                    ></span>
                                ))}
                            </div>
                        ))}
                </div>
                <div className="button-group">
                    <span className="button-group-text formatted-text">
                        Hope you are enjoying the game!❤️
                    </span>
                    <button
                        className={"button-submit button-group-button"}
                        onClick={() => window.location.reload(false)}
                    >
                        Play Again
                    </button>
                    {/* <button
                        className={"button-submit button-group-button"}
                        onClick={() => window.location.reload(false)}
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <span>Skip ad and play again</span>
                    </button> */}
                    <button
                        className={"button-submit button-group-button"}
                        onClick={redirectToIMAC}
                        style={{ display: "flex" }}
                    >
                        <ReactSVG src={CoffeeIcon} className="icon-coffee" />
                        Invite me a coffee!
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EndScreen;
