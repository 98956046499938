import React from "react";
import { ReactSVG } from "react-svg";

import "./CurrentRow.scss";
import CorrectIcon from "../../Assets/Icons/CorrectIcon.svg";
import IncorrectIcon from "../../Assets/Icons/IncorrectIcon.svg";
import { BoxWidth } from "../../Utilities/Vars";

const CurrentRow = ({ guess, updateGuess }) => {
    const handleClear = (color, index) => {
        if (color !== "white") {
            const newGuess = [...guess];
            newGuess[index] = "white";
            updateGuess(newGuess);
        }
    };

    return (
        <div className="row">
            <div className="cells-wrapper">
                {guess.map((color, index) => (
                    <td
                        key={index}
                        className="cell"
                        style={{ backgroundColor: color, width: BoxWidth() }}
                        onClick={() => handleClear(color, index)}
                    ></td>
                ))}
            </div>
            <div className="feedback-wrapper">
                <td>
                    <div className="feedback-item no-select correct">✔</div>
                </td>
                <td>
                    <div className="feedback-item no-select incorrect">✖</div>
                </td>
            </div>
        </div>
    );
};

export default CurrentRow;
