import React, { useEffect, useState } from "react";
import DynamicIcon from "../DynamicIcon/DynamicIcon";

const IconChanger = ({ characters = ["N/A"], font, size, colors = ["#000"], interval }) => {
    const [currentCharacterIndex, setCurrentCharacterIndex] = useState(0);

    useEffect(() => {
        if (characters.length === 0) return;

        const changeCharacter = () => {
            setCurrentCharacterIndex(
                (prevIndex) => (prevIndex + 1) % characters.length
            );
        };

        const intervalId = setInterval(changeCharacter, interval);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [characters, interval]);

    if (characters.length === 0) {
        return <div>No characters provided</div>; // Default message
    }

    return (
        <DynamicIcon
            character={characters[currentCharacterIndex]}
            font={font}
            size={size}
            color={colors[currentCharacterIndex]}
        />
    );
};

export default IconChanger;
